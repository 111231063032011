.container {
    font-family: Lato;
    margin-bottom: 3.5rem;
}

.teamWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem;
}

.title {
    font-size: 2.1875rem;
    text-align: center;
}

.titlePurple {
    color: var(--darkLilac);
}

.name {
    color: var(--darkLilac);
    font-size: 1rem;
    line-height: 1.31rem;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}

.job {
    font-size: 0.875rem;
    margin: 0.1rem 0;
    font-weight: 550;
}

.teamMemberWrapper {
    display: flex;
    flex-direction: column;
    width: calc(50% - 7px); /* Adjust the width with margin as needed */
    margin-bottom: 1.25rem;
}

.nameJobWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--lightLilac);
    padding-bottom: 0.5rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    min-height: 90px;
}

@media screen and (min-width: 768px){
    .teamWrapper {
        margin: 2rem;
    }
    
    .name {
        font-size: 1.25rem;
    }

    .job {
        font-size: 1rem;
    }


    .title{
        font-size: 2.8125rem;
        margin-bottom: 4rem;
        margin-top: 3rem;
    }
    .container{
        margin-bottom: 5rem;
    }

}

@media screen and (min-width: 1024px) {
    .container {
        margin: 0 4.5rem 10rem 4.5rem; /* Adjust the margin to be like in the features section */

    }
    .teamWrapper {
        flex-wrap: nowrap;
        justify-content: space-around;
        margin: 0;
    }
    .name{
        font-size: 1.3125rem;
    }
    .job{
        font-size: 1rem;
    }

    .teamMemberWrapper {
        margin: 1rem 0rem;
        width: calc(22% - 7px); /* Adjust the width with margin as needed */
    }

    .nameJobWrapper {
        min-height: 100px;
    }
    .title{
        font-size: 2.8125rem;
        margin-top: 4rem;
        margin-bottom: 2.5rem;
    }
}


@media screen and (min-width: 1600px){
    .container{
        margin: 0 11rem;
        margin-bottom: 10rem;
    }
}