.container {
    font-family: Lato;
    margin: 8rem 6rem;
}

.title {
    font-size: 2.8125rem;
    text-align: center;
    margin-bottom: 2.5rem;
    color: var(--darkLilac);
}

.container h3 {
    margin-bottom: 0.5rem;
    font-size: 1.875rem;
}

.container h4 {
    margin-bottom: 0.4rem;
    font-size: 1.1rem;
}

.container p {
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    color: var(--grey);
    line-height: 150%;
}

.container p.bold {
    margin-top: 0;
    font-weight: 500;
    color: black;
}

.container li{
    margin-top: 0.2rem;
    margin-bottom: 0.8rem;
    font-size: 1.25rem;
    color: var(--grey);
}

@media screen and (max-width: 768px) {
    .container {
        margin: 6rem 20px 0 20px;
    }

    .title {
        font-size: 2.1875rem;
    }

    .container h3 {
        font-size: 1.5rem;
    }

    .container h4 {
        font-size: 1rem;
    }

    .container p {
        font-size: 1.125rem;
    }

    .container li{
        font-size: 1.125rem;
    }
}