.nav {
    background: var(--white);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
}

.logoMobile{
    caret-color: transparent;
}

.navMenu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    width: 75vw;
    justify-content: end;
}

.navLinks {
    color: var(--black);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0rem 2rem;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
}

.navLinksActive {
    color: var(--darkLilac);
}

.navButton {
    border-radius: 34px;
    background: var(--darkLilac);
    width: 169px;
    height: 54px;
    border: none;
    color: var(--white);
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin: 0rem 0rem 0rem 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navButton:hover {
    opacity: 0.7;
    transition: 0.2s ease-in-out;
}

.navbar {
    background: linear-gradient(90deg, rgb(0, 255, 255) -50%, rgb(0, 143, 214) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navLinks:hover {
    color: var(--darkLilac);
    transition: all 0.1s ease-out;
}

.fa-bars {
    color: var(--darkOrange);
}

.navLinksMobile {
    display: none;
}

.menuIcon {
    display: none;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

@media screen and (max-width: 960px){
    .nav {
        justify-content: left;
    }

    .logoMobile {
        margin-left: 5%;
    }

    .navMenu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: flex-start;
        margin-top: 15px;
        border-radius: 35px;
    }

    .navMenuActive {
        background: var(--lightLilac);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .navLinks {
        text-align: center;
        padding: 2rem;
        display: table;
        margin: 15px auto 0;
    }

    .navLinks:hover {
        /* background-color: var(--darkLilac); */
        text-decoration: underline var(--darkLilac);
    }

    .navButton {
        margin: 30px auto 0;
    }

    .menuIcon {
        display: block;
        position: absolute;
        right: 0;
        cursor: pointer;
        margin-right: 10%;
        margin-bottom: 5px;
        color: var(--darkLilac);
        font-size: 25px;
        caret-color: transparent;
    }

    .fa-times {
        color: var(--darkOrange);
        font-size: 2rem;
    }
}

@media screen and (max-width: 768px){
    .nav {
        height: 80px;
    }

    .navMenu{
        margin-top: 0px;
    }

    .appLogo{
        height: 80px;
    }


}