.container {
    font-family: Lato;
    margin-bottom: 80px;
}

.title {
    color: var(--grey);
    font-weight: 500;
    text-align: center;
    font-size: 30px;
    margin-bottom: 40px;
}

.associatesWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 12rem;
}

.associatesWrapper:hover {
    cursor: pointer;
}

.imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 230px;
    height: 95px;
    margin: auto;
    margin-bottom: 1.25rem;
    box-shadow: 0px 10px 20px 5px var(--lightGrey);
    border-radius: 3px;
}

.imageWrapper:hover {
    box-shadow: 0px 10px 20px 10px var(--lightGrey);
}

.image {
    width: 180px;
    height: auto;
}

@media screen and (max-width: 1270px) {
    .imageWrapper {
        margin-bottom: 4rem;
        width: 34%;
        max-width: 230px;
    }

    .container {
        margin-bottom: 0;
    }

    .associatesWrapper {
        margin: 0 3rem;
    }
}

@media screen and (max-width: 800px) {
    .imageWrapper {
        margin-bottom: 3rem;
        width: 230px;
    }
}

@media screen and (max-width: 720px) {
    .associatesWrapper {
        margin: 0 1rem;
    }

    .imageWrapper {
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 500px) {
    .imageWrapper {
        margin-bottom: 2rem;
        width: 45%;
        height: 75px;
    }

    .image {
        width: 80%;
        height: auto;
    }
}