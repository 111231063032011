.container {
    background-color: var(--bannerLilac);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 35px;
    position: relative;
    margin-top: 80px;
    margin-bottom: 110px;
    padding-bottom: 350px;
    
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.69rem;
    font-family: Lato;
    font-size: 2.1875rem;
}

.titlePurple {
    color: var(--darkLilac);
}

.pLillac {
    color: var(--lightLilac);
}

.tagLine {
    display: none;
}

.description {
    color: var(--grey);
    font-family: Lato;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    margin: 0 1.3rem 1rem 1.3rem;
}



.iphoneImage{
    position:absolute;
    bottom: -3.5rem;
    width: 370px;
    max-width: 100%;
    caret-color: transparent;

}

#construction{
    bottom: 0rem;
}



.arrow  {
    display: none;
}

@media screen and (min-width: 340px) {
    .container {
        padding-bottom: 400px;
    }

    .iphoneImage{
        position:absolute;
        bottom: -4rem;
        width: 370px;
    }
    
}


@media screen and (min-width: 768px){
    .container {
        margin-top: 100px;

    }
    .description {
        margin: 0 3rem 1rem 3rem;
    }

}
@media screen and (min-width: 1024px) {
    .iphoneImage {
        position:relative;
        bottom: 0;
        width: auto;
        top:10px;
        margin: auto;
    }
    
    

    .tagLine {
        display: inline;
        font-family: Lato;
        font-size: 1.25rem;
    }

    .arrow {
        display: block;
        position: absolute;
        top: 95%; /* Position the image at the top of the container */
        left: 50%; /* Center the image horizontally */
        transform: translateX(-50%); /* Adjust the horizontal position */
        height: auto; /* Maintain the aspect ratio of the image */
    }

    .container {
        position: relative;
        overflow: visible;
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* flex-direction: row;
        align-items: normal;
        justify-content: space-evenly;*/
        padding: 2rem 0 1rem 0;
        
    }

    .contentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 2rem;
        padding-left: 104px;
    }

    

    .titleWrapper {
        font-size: 3.75rem;
        align-items: normal;
    }

    .description {
        font-size: 1.25rem;
        margin: 0;
        text-align: left;
    }
      
}

@media screen and (min-width: 1440px) {
    .description {
        max-width: 500px;
    }
}

