.container{
    font-family: Lato;
    background-color: var(--lightLilac);
}

.inputAndTextNewsletterContainer{
    background-color: white;
    border-radius: 0.625rem;
    border: 2px solid #6938D3;
}

.pnews{
    font-family: Lato;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    margin: 0.7rem auto;
}

.subscribeContainer{
    position: relative;

    width: 80%;
    margin: 0.5rem auto 1.3rem auto;
    height: 2.22rem;

}

.input {
    font-family: Lato;
    font-size: 0.875rem;
    color: var(--grey);
    font-weight: 400;


    border-radius: 0.13688rem;
    width: 80%;
    height: 100%;

    border: 1.095px solid var(--grey);
    padding-left: 1rem;


}

.input::placeholder{
    color: var(--grey);
}

.subscribeButton {
    font-family: Lato;
    font-size: 0.875rem;
    
    
    border-radius: 0.13688rem;
    background-color: var(--darkLilac);

    width: 40%;
    height: 111%;
    
    right:0;
    position: absolute;

    border-color: transparent;

    cursor: pointer;
    color: white;

}

.subscribeButton:hover{
    background-color: #7547d7fc;
    transition: 0.2s ease-in-out;
}

.contentContainer{
    margin: 0 1.25rem;

}

.logo {
    max-width: 10.25rem;
    max-height: 4.41813rem;
    position: relative;
    right: 1rem;
}

.socialsContainerHeading {
    margin: 2rem 0 0.5rem 0.2rem;
   
}

.socialsContainer > img{
    cursor: pointer;
}

.infoContainerColumnLinks:hover{
    opacity: 0.8;
    transition: 0.2s ease-in-out;
}


.logoAndSocialsContainer {
    display: flex;
    gap: 1.25rem;
    align-items: center;
    justify-content: space-between;
    
    
}

.socialsContainer{
    padding-right: 0.8rem;
}


.infoContainer {
    
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}



.infoContainerColumnHeading {
    font-family: Lato;
    color: var(--darkLilac);
    font-size: 1.25rem;
}

.infoContainerColumnLinks {
    font-family: Lato;
    font-size: 1rem;
    font-weight: 400;
    color: black;
    text-decoration: none;
    display: flex;
    margin-bottom: 1rem;
}



.textBelowLine {
    font-family: Lato;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--grey);
    padding: 0.5rem 0 1rem 0;

    display: flex;
    justify-content: space-between;
    border-top: var(--grey) 1px solid;
}

.textBelowLine p{
    margin: 0;
}

.textBelowLineLink {
    color: var(--grey);
}

.privacyAndTermsWrap {
    display: flex;
    justify-content: space-around;
}

.socialsContainerImg{
    margin-right: 0.5rem;
}

.socialsContainerImg:hover{
    opacity: 0.8;
    transition: 0.2s ease-in-out;
    
}

a{
    text-decoration: none;
    color: black;
    cursor: pointer;
}

@media screen and (min-width: 355px)
{
    .socialsContainer{
        position: relative;
        right: 5rem;
        padding-right: 0;
    }
}


@media screen and (min-width: 768px){

    
    .contentContainer{
        margin: 0 4rem;
        position: relative;
        bottom: 2rem;
    }

    .inputAndTextNewsletterContainer{
        margin: 0 auto;
        max-width: min(100%, 700px);
        position: relative;
        bottom: 2.5rem;
    }

    .subscribeButton {
        width: 30%;
    }

    .textBelowLine {
        margin-top: 1rem;
    }
    


}

@media screen and (min-width: 1024px){

    .contentContainer{
        margin: 0 7rem;
    }

    .pnews{
        font-size: 1.5625rem;
        margin: 0;
        margin-left: 1rem;
    }

    .inputAndTextNewsletterContainer{
        max-width: 54rem;
        height: 8.125rem;
        bottom: 4.5rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;        
    }

    .subscribeContainer{
        margin: 0;
        width: 50%;
        margin-right: 1rem;
    }

    .subscribeButton {
        font-size: 1rem;
        width: 35%;
        height: 112%;
    }

    .input{
        font-size: 1rem;
    }

    .logoAndSocialsContainer{
        flex-direction: column;
        align-items: flex-start;
    }

    .socialsAndInfoContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        max-width: 14.5rem;
        max-height: 6.25rem;
        position: relative;
        right: 1rem;
        
            

    }
    .socialsContainer{
        padding: 0;
        position: static;
    }

    .infoContainer{
        margin-top: 1.5rem;
        gap: 7rem;
    }

    .socialsAndInfoContainer{
        align-items: flex-start;
    }

    .infoContainerColumnHeading{
        font-size: 1.25rem;
        margin-bottom: 3rem;
    }

    .infoContainerColumnLinks{
        margin-bottom: 1.5rem;
    }
    
    .infoContainerColumnText{
        margin-bottom: 1.5rem;
    }

    .infoContainerColumnText:last-child{
        margin-top: 1.5rem;
    }

    .socialsContainerHeading{
        font-size: 1.25rem;
    }

    .infoContainer{
        margin-bottom: 0rem;
    }
}

@media screen and (min-width: 1400px){

    .contentContainer{
        margin: 0 10rem;
    }
}