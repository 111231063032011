.containerSection {
    font-family: Lato;
    margin: 0 20px;
}

.title {
    font-size: 2.1875rem;
    text-align: center;
    margin-bottom: 2.5rem;
}

.titlePurple {
    color: var(--darkLilac);
}


.featureContainer{
    max-width: 650px;
}

.line{
    display: none;
}

.featureHeading{
    font-size: 1.5rem;
    font-family: Lato;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 0;
    margin-top: 0;
}

.featureHeadingPurple{
    color: var(--darkLilac);
}

.featureDesc{
    color: var(--grey);
    font-family: Lato;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 150%;
    margin: 1.38rem 0;

    /*This is needed to preserve line breaks from JSON*/
    white-space:pre-line;
}

.featureDescOrange{ 
    color: var(--darkOrange);
}

.featurePic{
    width: min(100%, 450px);
    object-fit: cover;
    margin-bottom: 3.5rem;
}



@media screen and (min-width: 600px){
    .featureContainer{
        text-align: center;
        margin: 0 auto;
    }
}



@media screen and (min-width: 1024px) {

    .containerSection{
        margin: 0 3rem;
    }

    .featureContainer{
        display: flex;
        max-width: none;
        text-align:right;
        justify-content:center;
        margin-bottom: 4rem;
        max-height: 23rem;

    }

    .featureContainer:nth-child(odd){
        flex-direction: row-reverse;
        text-align: left;
    }

    

    .title{
        font-size: 2.8125rem;
        margin-bottom: 5rem;
    }

    

    .featurePic{
        margin-bottom: 0;
        width: 26rem;
        object-fit:scale-down;
    }
    
    .line{
        /*This is for the dotted lines in the middle of the page*/
        display:block;
        background-image: linear-gradient(to bottom, var(--darkLilac) 10%, rgba(255, 255, 255, 0) 0%);
        background-position: left;
        background-size: 2px 30px;
        background-repeat: repeat-y;
        margin: 0 4rem;
        
    }

    .temp{
        color: white;
    }

    .featureText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 26rem;
        
    }

    
    
    .featureHeading{
        font-size: 1.875rem;
    }

    .featureDesc{
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 1280px) {
    .featurePic{
        width: 30.75rem;
        object-fit: contain;
    }

    .featureText{
        
        max-width: 30.75rem;
        
    }

    .featureContainer{
        justify-content: space-evenly;
    }

   
}


