:root {
    --lightLilac: #F3EFFB;
    --darkLilac: #6938D3;
    --bannerLilac: #f3effb;	
    --white: #fff;
    --black: #000;
    --darkOrange: #FF6C00;
    --lightOrange: #FFB178;
    --grey: rgba(0,0,0,0.53);
    --lightGrey: rgba(0,0,0,0.15);
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
}