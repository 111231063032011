.container {
    font-family: Lato;
    margin: 0 20px;
    position: relative;

}

.title {
    font-size: 2.1875rem;
    text-align: center;
    margin-bottom: 2.5rem;
    margin-top: 0rem;
}

.titlePurple {
    color: var(--darkLilac);
}




.achievementsContainer {
    white-space: nowrap;
    overflow: hidden;
}

.displayFrame{
    /* transition: transform 0.3s; */
    transition: all 300ms;
}

.fastAnimation{
    transition: all 50ms;

}


.card{
    display: inline-flex;
    width: 100%;

    
    margin: 0 auto;
    margin-bottom: 1.5rem;
    
    
}

.innerCard{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageWrapper{
    display:flex;
    width: max(50%, 200px);
    max-width: 300px;

}

.image{
    margin: 0 auto;
    width: 100%;

}

.background{
    position: absolute;
    top: -1rem;
    padding-right: 50px;
    right: 20px;
    width: 100%;

    
    height: 50%;
    

    background-color: var(--lightLilac);
    border-radius: 0 0 1.25rem 1.25rem;
    z-index: -1;
}

.textContainer{
    text-align: center;
}

.cardTitle{
    color: #000;

    font-size: 1.5rem;
    font-weight: 600;
    line-height: 131%; /* 1.965rem */
}



.cardDescription{
    color: var(--grey);
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 150%;;
    white-space: normal;

    max-width: 600px;

}

.link{
    color: var(--grey);

    font-size: 1.125rem;
    font-weight: 550;
    line-height: 150%;
}

.link::after{
    content: url("/public/smallArrow.svg");
    vertical-align: sub;

    margin-left: 0.1rem;
}


.indicators{
    display: flex;
    justify-content: center;
    caret-color: transparent;
}

.indicatorButtons{
    background-color: var(--lightOrange);
    border: none;
    margin: 0 4px;
    
    width:  0.5625rem;
    height: 0.5625rem;
    border-radius: 50%;
    cursor: pointer;

}



.indicatorButtonsSelected{
    border: none;
    margin: 0 4px;

    background-color: var(--darkOrange);
    width: 1.75rem;
    height: 0.5625rem;
    border-radius: 0.9375rem;
}

.navigation{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.leftArrow, .rightArrow{
    border:none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    /*Is needed because of the arrowDisable css, should be the same width as arrowImage*/
    width: 1rem;

}

.arrowDisable{
    display: none;
}

.leftArrow img{
    transform: rotate(180deg);
}

.arrowImage{
    width: 1rem;
    filter:opacity(0.5);
}

.arrowImage:hover{
    filter:opacity(1);
}

@media screen and (min-width: 600px){
    .leftArrow, .rightArrow{
        width: 1.25rem;
    }

    .arrowImage{
        width: 1.25rem;
    }

    .navigation{
        gap: 6rem;
    }

    .indicatorButtons:hover{
        width: 1.75rem;
        height: 0.5625rem;
        border-radius: 0.9375rem;
        transition: ease-in-out 0.3s;
        transform: scaleX(1.1);
    }
}

@media screen and (min-width: 1024px) {

    .card{
        justify-content: center;
    }
    .innerCard{
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 1000px;
        gap: 6rem;
    }
   
    .title{
        font-size: 2.8125rem;
    }

    .cardTitle{
        font-size: 1.875rem;
    }
    
    .cardDescription{
        font-size: 1.25rem;
        max-width: 450px;

    }


    /* Using flex basis 50% to keep the middle centered, flex-grow wasnt working */
    .textContainer{
        text-align: start;
        flex-basis: 50%;

    }

    

    .imageWrapper{
        flex-basis: 50%;
        max-width: unset;
        justify-content: flex-end;
        width: unset;
    }

    .image{
        margin: 0;
        max-width: 400px;

    
    }

    .navigation{
        margin-top: 1.5rem;
    }
}

@media screen and (min-width: 1240px) {
    .container{
        position: relative;
    }

    .leftArrow, .rightArrow{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.75rem;
    }


    /* left and right is calculated based on the max-width of the innerCard container
    500px is half of the max width, 100px is for the "padding" */
    .leftArrow{
        
        left: calc(50% - 500px - 100px);
    }

    .rightArrow{
        right: calc(50% - 500px - 100px);
    }

    .arrowImage{
        width: 1.75rem;
    }

}
